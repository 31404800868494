<template>
  <CRow class="dependantOn-detail">
    <CCol col="12">
      <CCard class="zq--wizard-card">
        <CCardHeader @click="basicIsCollapsed = !basicIsCollapsed">
          <div class="d-flex justify-content-between">
            <strong class="title">{{ texts.createPage.summary.dependantOn.title }}</strong>
            <CLink class="card-header-action btn-minimize">
              <ClCardArrow :is-collapsed="basicIsCollapsed" />
            </CLink>
          </div>
        </CCardHeader>
        <CCollapse :show="basicIsCollapsed" :duration="400">
          <CCardBody>
            <!--      Should Match At Least      -->
            <ClCardRow
              v-if="data.selectedData.length"
              :label="texts.createPage.summary.dependantOn.shouldLabel"
              :content="data.formData.dependantOn.shouldMatchAtLeast"
            />
            <!--       TABLE     -->
            <CDataTable
              v-if="data.selectedData.length"
              id="achTableResult"
              class="zq--table zq--table--default-pagination"
              :items="data.selectedData"
              :fields="fields"
              :items-per-page="itemsPerPage"
              :sorter="{ external: true }"
              pagination
              :responsive="true"
              :border="isDefaultTheme"
              :hover="isDefaultTheme"
              :striped="isDefaultTheme"
            >
              <!--      CREATED         -->
              <template #created="{item}">
                <td>{{ dateFormate(item.created) }}</td>
              </template>
              <!--       Description           -->
              <template #description="{ item }">
                <td>
                  <span v-html="item.description"></span>
                </td>
              </template>
              <!--       Dependency           -->
              <template #dependency="{ item }">
                <td>
                  <div class="d-flex justify-content-between align-items-center">
                    <div class="action-buttons-list">
                      <button
                        class="action-buttons action-buttons__must-not"
                        :class="{'action-buttons--active__must-not': data.formData.dependantOn.mustNot.includes(item.id)}"
                      >
                        {{ texts.createPage.summary.entrants.mustNotText }}
                      </button>
                      <button
                        class="action-buttons action-buttons__should"
                        :class="{'action-buttons--active__should': data.formData.dependantOn.should.includes(item.id)}"
                      >
                        {{ texts.createPage.summary.entrants.shouldText }}
                      </button>
                      <button
                        class="action-buttons action-buttons__must"
                        :class="{'action-buttons--active__must': data.formData.dependantOn.must.includes(item.id)}"
                      >
                        {{ texts.createPage.summary.entrants.mustText }}
                      </button>
                    </div>
                  </div>
                </td>
              </template>
            </CDataTable>
          </CCardBody>
        </CCollapse>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import ClCardRow from '@/shared/UI/ClCardRow';
import ClCardArrow from '@/shared/UI/ClCardArrow';
import {achievementsTexts} from "@/config/pageTexts/achievements.json";
import {pageConfig} from "@/config";
import {mapGetters} from "vuex";
import {dateFormate} from "@/utils";

export default {
  name: 'DependantOn',
  components: {
    ClCardRow,
    ClCardArrow
  },
  props: {
    dependantOnData: Object,
    isPreview: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      texts: {
        ...achievementsTexts
      },
      basicIsCollapsed: false,
      data: {},
      dataMap: {},
      fields: [
        "id",
        "name",
        "created",
        "description",
        "dependency"
      ],
      page: pageConfig.page,
      itemsPerPage: pageConfig.itemsPerPage,
    }
  },
  computed: {
    ...mapGetters('theme', ['theme']),
    isDefaultTheme() {
      return this.theme === 'default'
    }
  },
  created() {
    // this.data = this.dependantOnData.formData;
    this.data = this.dependantOnData;
    this.dataMap = this.createDataMap();
  },
  methods: {
    createDataMap() {
      let dataMap = {};

      if (this.dependantOnData.selectedData && this.dependantOnData.selectedData.length) {
        this.dependantOnData.selectedData.forEach(item => {
          dataMap[item.id] = item.name;
        })
      }

      return dataMap;
    },
    dateFormate(val) {
      return dateFormate(val);
    },
  },
}
</script>

<style lang="scss">
.dependantOn-detail {

}
</style>