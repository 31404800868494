<template>
  <CCol col="12" class="p-0" v-if="messageSettings">
    <CCard class="zq--wizard-card" >
      <CCardHeader @click="messageSettingsIsCollapsed = !messageSettingsIsCollapsed">
        <div class="d-flex justify-content-between">
          <strong class="title">{{ 'Messages Settings' }}</strong>
          <CLink class="card-header-action btn-minimize">
            <ClCardArrow :is-collapsed="messageSettingsIsCollapsed" />
          </CLink>
        </div>
      </CCardHeader>
      <CCollapse :show="messageSettingsIsCollapsed" :duration="400">
        <CCardBody>
          <div class="zq-card-row">
            <div class="zq-card-row--label">
              <div class="overview-label">{{ texts.celebrationMessageHeader }}:</div>
            </div>
            <div class="zq-card-row--content">
              <div v-html="messageSettings.celebrationMessage"></div>
            </div>
          </div>
          <div class="zq-card-row">
            <div class="zq-card-row--label">
              <div class="overview-label">{{ texts.celebrationMessageBody }}:</div>
            </div>
            <div class="zq-card-row--content">
              <div v-html="messageSettings.celebrationText"></div>
            </div>
          </div>
          <div class="zq-card-row">
            <div class="zq-card-row--label">
              <div class="overview-label">{{ texts.celebrationImage }}:</div>
            </div>
            <div class="zq-card-row--content">
              <img class="icon-preview" v-if="celebrationImageSrc" :src="celebrationImageSrc" alt="Celebration" />
            </div>
          </div>
          <div class="zq-card-row">
            <div class="zq-card-row--label">
              <div class="overview-label">{{ texts.celebrationAnimation }}:</div>
            </div>
            <div class="zq-card-row--content">
              <div v-html="messageSettings.isCelebrationAnimation"></div>
            </div>
          </div>

          <div class="zq-card-row">
            <div class="zq-card-row--label">
              <div class="overview-label">{{ texts.loseMessageHeader }}:</div>
            </div>
            <div class="zq-card-row--content">
              <div v-html="messageSettings.sorryMessage"></div>
            </div>
          </div>
          <div class="zq-card-row">
            <div class="zq-card-row--label">
              <div class="overview-label">{{ texts.loseMessageBody }}:</div>
            </div>
            <div class="zq-card-row--content">
              <div v-html="messageSettings.sorryText"></div>
            </div>
          </div>
          <div class="zq-card-row">
            <div class="zq-card-row--label">
              <div class="overview-label">{{ texts.loseImage }}:</div>
            </div>
            <div class="zq-card-row--content">
              <img class="icon-preview"  v-if="sorryImageSrc" :src="sorryImageSrc" alt="Celebration" />
            </div>
          </div>
          <div class="zq-card-row">
            <div class="zq-card-row--label">
              <div class="overview-label">{{ texts.loseAnimation }}:</div>
            </div>
            <div class="zq-card-row--content">
              <div v-html="messageSettings.isSorryAnimation"></div>
            </div>
          </div>
        </CCardBody>
      </CCollapse>
    </CCard>
  </CCol>
</template>

<script>
import { mapActions } from 'vuex';
import { instantWinsTexts } from '@/config/pageTexts/instantWins.json';
import ClCardArrow from '@/shared/UI/ClCardArrow.vue';

export default {
  name: 'CelebrationMessagePreview',
  components: { ClCardArrow },
  props: {
    messageSettings: {
      type: Object,
      default: () => ({})
    },
  },
  data() {
    return {
      messageSettingsIsCollapsed: true,
      sorryImageSrc: '',
      celebrationImageSrc: '',
      texts: {
        ...instantWinsTexts.createPage.messagesStep
      }
    }
  },
  watch: {
    messageSettings: {
      immediate: true,
      async handler(newVal) {
        if (newVal) {
          if (newVal.sorryImage) {
            this.sorryImageSrc = await this.getIcon(newVal.sorryImage);
          }
          if (newVal.celebrationImage) {
            this. celebrationImageSrc = await this.getIcon(newVal.celebrationImage);
          }
        }
      }
    }
  },
  methods: {
    ...mapActions('files', ['handleGetFileObjects_item']),
    async getIcon(id) {
      const file = await this.handleGetFileObjects_item({idArray: [id]});

      if (Array.isArray(file) && file.length && file[0].uri) {
        return file[0].uri;
      }
    },
  }
};
</script>

<style scoped lang="scss">
.icon-preview {
  max-height: 80px;
  max-width: 80px;
}
</style>