<template>
  <div class="create-summary">
    <div class="form-content content position-relative">
      <h3 class="mb-3 bread-content">{{ stepNumber }}. {{ pageTitle }}</h3>
      <Settings
        v-if="Object.keys(settingsData).length && Object.keys(modelFields).length && !isInstantWin"
        :settingsData="settingsData"
        :modelFields="modelFields"
        :isInstantWin="isInstantWin"
      />
      <div v-if="isInstantWin" class="row settings-detail">
        <Settings
          class="col-8 h-100"
          :settingsData="settingsData"
          :modelFields="modelFields"
          :isInstantWin="isInstantWin"
        />
        <CCol class="d-flex p-0 col-4 h-100 instant-win-preview">
          <Wheel
            v-if="instantWinType === 1"
            :sections-count="instantWinTileData.length"
            :tilesData="instantWinTileData"
            :isHideArrow="true"
            :isVerticallyText="isVerticallyText"
            :wheelSettings="wheelSettings"
            :isShowWheelImage="true"
          />
          <div class="scratch-card-preview">
            <ScratchCard
              v-if="instantWinType === 2"
              :tilesData="instantWinTileData"
              :tilesCount="instantWinTileData.length"
              :cols="instantWinTileData[instantWinTileData.length - 1].location.col"
              :rows="instantWinTileData[instantWinTileData.length - 1].location.row"
              :previewMode="true"
            />
          </div>

        </CCol>
      </div>
      <Scheduling
        v-if="Object.keys(schedulingData).length"
        :schedulingData="schedulingData"
      />
      <AwardScheduling
        v-if="awardSchedulingData.length"
        :schedulingData="awardSchedulingData"
      />
      <EntrantsTags
        v-if="Object.keys(entrantsData).length"
        :entrantsData="entrantsData"
      />
      <DependantOn
        v-if="Object.keys(dependantOnData).length"
        :dependantOnData="dependantOnData"
      />
      <Rewards
        v-if="rewardsData.length || showEmptyRewardsData"
        :rewardsData="rewardsData"
      />
      <ViewPointsStrategy
        v-if="strategiesData"
        :score-points-data="strategiesData"
      />
      <ViewRules
        v-if="Object.keys(rulesData).length"
        :rulesData="rulesData"
        context="achievement"
      />
      <Products
        v-if="Object.keys(productsData).length"
        :productsData="productsData"
      />
      <InstantWinTileView
        v-if="instantWinTileData.length"
        :instantWinTileData="instantWinTileData"
      />
      <CelebrationMessagePreview
        v-if="Object.keys(messageSettings).length"
        :messageSettings="messageSettings"
      />
      <ViewTranslations
        v-if="Object.keys(translationsData).length"
        :entityData="settingsData"
        :createTranslationsData="translationsData"
        :translatableFields="translatableFields"
      />
    </div>
  </div>
</template>

<script>
import Settings from '@/shared/components/steps/components/Settings';
import ViewRules from '@/shared/components/supportModels/rules/ViewRules';
import DependantOn from './components/DependantOn';
import Scheduling from './components/Scheduling';
import AwardScheduling from './components/AwardScheduling';
import ViewTranslations from '@/shared/components/supportModels/translations/ViewTranslations';
import Rewards from './components/Rewards';
import Products from './components/Products';
import EntrantsTags from './components/EntrantsTags';
import ViewPointsStrategy from '@/shared/components/supportModels/achievements/ViewPointsStrategy';
import InstantWinTileView from '@/shared/components/steps/components/instantWinTileView.vue';
import ScratchCard from '@/views/ziqni/instant-wins/components/ScratchCard.vue';
import Wheel from '@/views/ziqni/instant-wins/components/Wheel.vue';
import CelebrationMessagePreview from '@/views/ziqni/instant-wins/CelebrationMessagePreview.vue';

export default {
  name: 'SummaryStep',
  components: {
    CelebrationMessagePreview,
    Wheel, ScratchCard,
    InstantWinTileView,
    ViewPointsStrategy,
    EntrantsTags,
    Settings,
    ViewRules,
    DependantOn,
    Scheduling,
    AwardScheduling,
    ViewTranslations,
    Rewards,
    Products,
  },
  props: {
    settingsData: {
      type: Object,
      default() {
        return {}
      }
    },
    translatableFields: {
      type: Array,
      default: () => [],
    },
    rulesData: {
      type: Object,
      default() {
        return {}
      }
    },
    dependantOnData: {
      type: Object,
      default() {
        return {}
      }
    },
    schedulingData: {
      type: Object,
      default() {
        return {}
      }
    },
    awardSchedulingData: {
      type: Array,
      default() {
        return []
      }
    },
    entrantsData: {
      type: Object,
      default() {
        return {}
      }
    },
    productsData: {
      type: Object,
      default() {
        return {}
      }
    },
    translationsData: {
      type: Object,
      default() {
        return {}
      }
    },
    rewardsData: {
      type: Array,
      default() {
        return []
      }
    },
    showEmptyRewardsData: {
      type: Boolean,
      default: false,
    },
    stepNumber: {
      type: Number,
      default: 8
    },
    pageTitle: {
      type: String,
      default: 'Summary'
    },
    model: {
      type: String,
      default: ''
    },
    strategiesData: {
      type: Object,
      default() {
        return null
      }
    },
    instantWinType: Number,
    instantWinTileData: {
      type: Array,
      default() {
        return []
      }
    },
    isVerticallyText: {
      type: Boolean,
      default: false
    },
    wheelSettings: {
      type: Object,
      default() {
        return {}
      }
    },
    messageSettings: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data () {
    return {
      texts: {},
      modelFields: {},
      isInstantWin: false
    }
  },
  async created() {
    this.isInstantWin = !!this.instantWinTileData.length;

    let fieldsModule = await import("@/generated/ziqni/store/modules/" + this.model + "/fields" );
    this.modelFields = fieldsModule.default;
    delete this.modelFields.baseFields.fields.maxNumberOfIssues;
  },
}
</script>

<style lang="scss">
.create-summary {
  height: 100%;
  flex: 2;
  .form-content {
    height: calc(100% - 50px);
    background: var(--zq-sub-bg);

    .instant-win-preview {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 12px;
      margin-bottom: 24px;
      background: #fff;

      .scratch-card-preview {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 330px;

        .scratch-cards-block {
          height: 210px;
          padding: 0;
        }
      }


    }
  }
}
</style>